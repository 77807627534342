<template>
  <div class="readme-article">
    <h1 id="成员管理">七、成员管理</h1>
    <p>
      员工列表，可根据左侧部分筛选员工，员工信息包含头像、姓名、所属部门、手机号等信息。
    </p>
    <p>
      <img src="@/assets/img/scrm/7-1.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm7-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>